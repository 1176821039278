<template>
  <div>
    <div class="edit-div">
      <div class="form-container">
        <p class="form-title" v-if="tourAgency == ''">Inscription</p>
        <p class="form-title" v-if="tourAgency !== ''">
          Information du nouveau compte
        </p>
        <div class="form-element">
          <label for="name">{{ $t("label.name") }}</label>
          <input type="text" id="name" v-model="name" required />
        </div>
        <div class="form-element">
          <label for="surname">{{ $t("label.surname") }}</label>
          <input type="text" id="surname" v-model="surname" required />
        </div>
        <div class="form-element">
          <label for="email">{{ $t("label.email") }}</label>
          <input type="email" id="email" v-model="email" required />
        </div>
        <div class="form-element">
          <label for="emailConfirmation">{{
            $t("label.emailConfirmation")
          }}</label>
          <input
            type="email"
            id="emailConfirmation"
            v-model="emailConfirmation"
            required
          />
          <div class="error-message" v-if="emailCheck != ''">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
              />
            </svg>
            <span>{{ emailCheck }}</span>
          </div>
        </div>
        <div class="form-element">
          <span class="label">{{ $t("label.gender") }}</span>
          <ul class="form-list">
            <li>
              <input
                type="radio"
                id="male"
                v-model="gender"
                value="male"
                checked="checked"
              />
              <label for="male">{{ $t("label.male") }}</label>
            </li>
            <li>
              <input type="radio" id="female" v-model="gender" value="female" />
              <label for="female">{{ $t("label.female") }}</label>
            </li>
          </ul>
        </div>
        <div class="double-form-element">
          <div>
            <label for="language">{{ $t("label.language") }}</label>
            <p class="select">
              <select id="language" v-model="language" required>
                <option value="en-US">English (US)</option>
                <option value="en-GB">English (GB)</option>
                <option value="fr-FR">Français</option>
              </select>
            </p>
          </div>
          <div>
            <label for="birthDate" v-if="tourAgency == ''">{{
              $t("label.birthdate")
            }}</label>
            <input
              type="date"
              id="birthDate"
              v-model="birthDate"
              required
              v-if="tourAgency == ''"
            />
          </div>
        </div>
      </div>
      <div class="form-container" v-if="tourAgency == ''">
        <div class="form-element">
          <label for="password">{{ $t("password.password") }}</label>
          <input
            type="password"
            id="password"
            v-model="password"
            :class="passwordCheckStyle"
          />
        </div>
        <div class="form-element">
          <label for="passwordConfirmation">{{
            $t("password.confirmation")
          }}</label>
          <input
            type="password"
            id="passwordConfirmation"
            v-model="passwordConfirmation"
          />
        </div>
        <div class="error-message" v-if="passwordCheck != ''">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"
            />
          </svg>
          <span>{{ passwordCheck }}</span>
        </div>
        <div class="form-element">
          <label for="phone">{{ $t("label.phone") }}</label>
          <input type="text" id="phone" v-model="phone" />
        </div>
        <div class="app-container">
          <div
            v-for="(service, index) in messageApp"
            :key="service.id"
            class="app"
          >
            <div class="app-input">
              <label for="app">App</label>
              <select id="app" v-model="service.app">
                <option value="telegram">Telegram</option>
                <option value="personal_matrix">Matrix (Element / Riot)</option>
              </select>
            </div>
            <div class="app-input">
              <label for="identifier">{{ $t("label.identifier") }}</label>
              <input type="text" id="identifier" v-model="service.identifier" />
            </div>
            <div>
              <button
                type="button"
                @click="openHelp(service.app)"
                class="help-button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M14.601 21.5c0 1.38-1.116 2.5-2.499 2.5-1.378 0-2.499-1.12-2.499-2.5s1.121-2.5 2.499-2.5c1.383 0 2.499 1.119 2.499 2.5zm-2.42-21.5c-4.029 0-7.06 2.693-7.06 8h3.955c0-2.304.906-4.189 3.024-4.189 1.247 0 2.57.828 2.684 2.411.123 1.666-.767 2.511-1.892 3.582-2.924 2.78-2.816 4.049-2.816 7.196h3.943c0-1.452-.157-2.508 1.838-4.659 1.331-1.436 2.986-3.222 3.021-5.943.047-3.963-2.751-6.398-6.697-6.398z"
                  />
                </svg>
              </button>
            </div>
            <div>
              <button
                @click="messageApp.splice(index, 1)"
                class="remove-app-button"
                v-if="service.app != 'matrix'"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="white"
                  width="8"
                  height="8"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="add-app">
          <button
            @click="messageApp.push({ app: '', identifier: '' })"
            class="add-app-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              width="12"
              height="12"
              viewBox="0 0 24 24"
            >
              <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
            </svg>
          </button>
        </div>
      </div>

      <div class="conditions" v-if="tourAgency == ''">
        <div class="conditions-item">
          <label for="tac-check"
            >{{ $t("label.RaCheck") }}
            <a
              href="https://www.travelassist.io/conditions-generales-de-vente/"
              target="_blank"
            >
              {{ $t("label.tac") }}</a
            >
          </label>
          <input type="checkbox" v-model="acceptTAC" id="tac-check" />
        </div>
        <div class="conditions-item">
          <label for="legals-check"
            >{{ $t("label.RaCheck") }}
            <a
              href="https://www.travelassist.io/mentions-legales/"
              target="_blank"
            >
              {{ $t("label.legalNotices") }}
            </a>
          </label>
          <input type="checkbox" v-model="acceptNotices" id="legals-check" />
        </div>
        <div class="conditions-item">
          <label for="pp-check"
            >{{ $t("label.RaCheck") }}
            <a
              href="https://www.travelassist.io/politique-de-confidentialite/"
              >{{ $t("label.privacyPolicy") }}</a
            >
          </label>
          <input type="checkbox" v-model="acceptPP" id="pp-check" />
        </div>
      </div>
      <br />
      <button
        id="confirmation-button"
        @click="submitEdit"
        :disabled="checkForm"
        class="save-button"
      >
        {{ $t("buttons.save") }}
      </button>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "ClientForm",
  data() {
    return {
      edit: false,
      name: "",
      surname: "",
      email: "",
      birthDate: "",
      password: "",
      passwordConfirmation: "",
      phone: "",
      messageApp: [],
      travels: [],
      voucher: [],
      language: navigator.language || navigator.userLanguage,
      gender: "male",
      emailConfirmation: "",
      acceptPP: false,
      acceptNotices: false,
      acceptTAC: false,
    };
  },
  props: {
    tourAgency: {
      type: String,
      default: "",
    },
    noRedir: { type: Boolean, default: false },
  },
  computed: {
    checkForm: function () {
      if (!this.name) {
        return true;
      }
      if (!this.surname) {
        return true;
      }
      if (!this.email) {
        return true;
      }
      if (!this.language) {
        return true;
      }
      if (!this.gender) {
        return true;
      }
      if (!this.birthDate && !this.tourAgency) {
        return true;
      }
      if (this.password.length < 8 && !this.tourAgency) {
        return true;
      }
      if (this.password != this.passwordConfirmation && !this.tourAgency) {
        return true;
      }
      if (this.email != this.emailConfirmation) {
        return true;
      }
      return false;
    },
    passwordCheck: function () {
      if (this.password.length < 8 && this.password.length > 0) {
        return this.$t("password.min8caracters");
      }
      if (
        this.password != this.passwordConfirmation &&
        this.passwordConfirmation !== ""
      ) {
        return this.$t("password.confirmationMustMatch");
      }
      return "";
    },
    emailCheck: function () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email.toLowerCase())) {
        if (
          this.email != this.emailConfirmation &&
          this.emailConfirmation != ""
        ) {
          return this.$t("error.emailCheck");
        }
        return "";
      } else {
        if (this.email != "") {
          return this.$t("error.invalidEmail");
        } else {
          return "";
        }
      }
    },
    passwordCheckStyle: function () {
      if (
        this.password.length < 8 &&
        this.password.length > 0 &&
        !this.tourAgency
      ) {
        return "errorField";
      }
      if (this.password != this.passwordConfirmation && !this.tourAgency) {
        return "errorField";
      }
      return "normalField";
    },
  },

  methods: {
    openHelp(app) {
      if (app == "telegram") {
        let routeData = this.$router.resolve({ name: "telegramhelp" });
        window.open(routeData.href, "_blank");
      }
    },
    submitEdit: async function () {
      try {
        this.$store.dispatch("load", "Registering user");
        let client_travel = [];
        for (let i = 0; i < this.travels.length; i++) {
          client_travel.push(this.travels[i]._id);
        }
        let newData = {
          name: this.name,
          surname: this.surname,
          email: this.email,
          birthDate: this.birthDate,
          messageApp: this.messageApp,
          language: this.language,
          gender: this.gender,
          acceptPP: this.acceptPP,
          acceptNotices: this.acceptNotices,
          acceptTAC: this.acceptTAC,
        };
        if (this.tourAgency) {
          newData["tourAgency"] = this.tourAgency;
        } else {
          newData["password"] = this.password;
          newData["firstConnexion"] = false;
        }
        if (this.travel) {
          newData["travel"] = this.travel;
        }
        if (this.voucher) {
          newData["voucher"] = this.voucher;
        }
        if (this.phone) {
          newData["phone"] = this.phone;
        }
        let res = null;
        if (this.tourAgency) {
          res = await HandleApi.postAgencyClient(this.tourAgency, newData);
        } else {
          res = await HandleApi.postClient(newData);
        }
        if (res.status == 200) {
          this.$store.dispatch("unload");
          this.$store.dispatch(
            "push_notification_message",
            this.$t("notification.created", {
              object: this.$t("notification.clientProfile"),
            })
          );
          if (this.noRedir) {
            this.$emit("clientcreated");
            this.$modal.hide("newClient-modal");
          } else {
            if (this.tourAgency) {
              this.$router.push("/client/" + res.data);
            } else {
              this.$router.push("/login");
            }
          }
        }
      } catch (err) {
        this.$store.dispatch("unload", "Requesting Data");
        this.$store.dispatch("push_error_message", err);
      }
    },
  },
};
</script>

<style scoped>
.error-message {
  /*border: 1px solid red;*/
  color: white;
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.5);
  max-width: 90%;
  padding: 10px 10px;
  margin: 0 auto;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-message svg {
  margin-right: 10px;
}
.conditions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.app {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.app > div > label {
  margin-right: 5px;
  display: block;
  font-weight: bold;
  color: #666666;
}
.app > div {
  margin-right: 10px;
}
.app-input > input,
.app-input > select {
  width: 100%;
  display: block;
  margin: 5px 0;
  padding: 6px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.app-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.help-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
  border: none;
  padding: 0;
  cursor: pointer;
}
.remove-app-button {
  margin: 10px;
  color: white;
  height: 21px;
  width: 21px;
  text-align: center;
  background-color: #ff3a3a;
  border-radius: 30px;
  border: none;
  padding: 0;
  cursor: pointer;
}

.add-app {
  width: 100%;
}
.add-app-button {
  display: block;
  margin: 5px auto;
  background-color: #37bbf8;
  color: white;
  border: none;
  border-radius: 0.25em;
  font-weight: bold;
  padding: 10px;
  line-height: 12px;
  cursor: pointer;
}
.save-button {
  display: block;
  margin: 10px auto;
  background-color: rgb(9, 201, 111);
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}

.save-button:disabled {
  background-color: rgb(139, 196, 169);
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}
.back-button {
  background-color: rgb(255, 72, 72);
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 20px;
  font-weight: bold;
  padding: 5px 20px;
  font-size: 20px;
}
.errorField {
  -moz-box-shadow: 0 0 3px red;
  -webkit-box-shadow: 0 0 3px red;
  box-shadow: 0 0 3px red;
}
.form-container {
  width: 30%;
  margin: 10px auto 30px auto;
  padding: 0 5%;
}
@media (max-width: 1000px) {
  .form-container {
    width: 80%;
  }
}
.form-title {
  font-size: 110%;
  font-weight: bold;
}
.form-element {
  position: relative;
  margin: 20px 0;
}

.double-form-element {
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.double-form-element > div {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.double-form-element > div:nth-child(1) {
  align-self: flex-start;
  padding: 0 10px 0 0;
}
.double-form-element > div:nth-child(2) {
  align-self: flex-end;
  padding: 0 0 0 10px;
}
.form-element > input,
.double-form-element > div > input {
  width: 100%;
  display: block;
  margin: 5px 0;
  padding: 8px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-element > input:disabled,
.double-form-element > div > input:disabled {
  background-color: #e5e5e5;
}
.form-element > input:focus,
.double-form-element > div > input:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
.form-element > span {
  display: block;
}
.form-element > label,
.label,
.double-form-element > div > label {
  display: block;
  font-weight: bold;
  color: #666666;
}
/* Form list */
.form-list {
  display: inline-block;
  padding: 0;
  margin: 5px 0;
}
.form-list::after {
  clear: both;
  content: "";
  display: table;
}
.form-list > li {
  display: inline-block;
  position: relative;
  user-select: none;
  margin: 0 26px 16px 0;
  float: left;
}
/* Custom select element */
.double-form-element > div > p {
  margin: 5px 0;
}
.double-form-element > div > p > select {
  display: block;
  width: 100%;
  appearance: none;
}
.double-form-element > div > p > select {
  padding: 10px;
  border: 1px solid #cfd9db;
  background-color: white;
  border-radius: 0.25em;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.double-form-element > div > p > select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 141, 222, 0.2);
}
.double-form-element > div > .select {
  position: relative;
}
.double-form-element > div > .select::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  margin-top: -8px;
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Cpolygon%20fill%3D%22%232c3e50%22%20points%3D%220.9%2C5.5%203.1%2C3.4%208%2C8.3%2012.9%2C3.4%2015.1%2C5.5%208%2C12.6%20%09%22%2F%3E%0D%0A%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E")
    no-repeat center center;
  pointer-events: none;
}
.double-form-element > div > p > select {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.double-form-element > div > p > select::-ms-expand {
  display: none;
}
/* Custom Radio & checkbox Button */
.form-list input[type="radio"],
.form-list input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.form-list input[type="radio"] + label,
.form-list input[type="checkbox"] + label {
  padding-left: 24px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.form-list input[type="radio"] + label::before,
.form-list input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.form-list input[type="radio"] + label::before,
.form-list input[type="radio"] + label::after {
  border-radius: 50%;
}
.form-list input[type="checkbox"] + label::before,
.form-list input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.form-list input[type="radio"] + label::after,
.form-list input[type="checkbox"] + label::after {
  background-color: #2c3e50;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.4);
  display: none;
}
.form-list input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.form-list input[type="radio"]:focus + label::before,
.form-list input[type="checkbox"]:focus + label::before {
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.6);
}
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::after {
  display: block;
}
.form-list input[type="radio"]:checked + label::before,
.form-list input[type="radio"]:checked + label::after,
.form-list input[type="checkbox"]:checked + label::before,
.form-list input[type="checkbox"]:checked + label::after {
  animation: cd-bounce 0.3s;
}

@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@-moz-keyframes cd-bounce {
  0%,
  100% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}
</style>
